.uCard {
    margin: 10px;
    padding: 10px;
    margin-left: 0px;
    border: 1px solid black;
    text-align: left;
    
}

.followheader{
    width: 100%;
    margin: 0 auto  !important;
}

.linediv{
    display: flex !important;
    justify-content: space-between !important;
    width: 100% !important;
}

.follows{
    margin-top: 10px;
}

.line{
    display: inline-block;
    margin-right: 10px;
}

.addnew:hover{
    cursor: pointer;

}

.addnew{
    border-radius: 10px;
}

.newData{
    display: none;
}