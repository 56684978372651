.ndata{
    width: 90% !important;
    padding: 10px !important;
    margin: 10px !important;
    display: block;
    background: #f0efef !important;
    border-color:rgb(148, 207, 255) !important;
    border-width: 3px !important;
}
.addnewdiv{
    padding: 10px;
    border: 1px solid green;
    margin: 10px;
    margin-left: 0px;
}
#txt{
    height: 200px !important;
}


.ndata:focus {
    background-color: #ddd;
    outline: none;
}

.statuslabel{
    margin: 10px;
}
.statusdata{
    margin: 10px;
}

#centerbtn{
    display: block;
    margin: 0 auto;
    border-radius: 10px;
}