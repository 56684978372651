*{
    -webkit-box-orient: vertical;

}

.inputdata {
    width: 60% !important;
    padding: 10px !important;
    margin: 10px !important;
    display: block;
    background: #f0efef !important;
    border-color:rgb(148, 207, 255) !important;
    border-width: 3px !important;
  }
  .inputdata:focus {
    background-color: #ddd;
    outline: none;
  }
  /* .App {
    padding: 0px;
    width: 100%;
    text-align: center;
    border: 1px solid black;
  } */

  .form-control{
    display: inline;
  }
  .mt-3 {
    padding: 10px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }


  button {
    width:30% !important;
    color: #DC3545 !important;
    padding: 14px 25px !important;
    margin-top: 15px;
    margin-left: -5px;
    cursor: pointer;
    border-color: #f35060 !important;
    border-width: 3px !important;
    opacity: 0.9;
    text-align: center;
    transition: 1s;
  }
  
  button:hover {
    background-color: #DC3545 !important;
    color: #fff !important;
  }
  .socialG{
    margin-left:20px !important;
  }
  .centerS{
    margin-left:20px !important;
  }

  .centerL {
    text-align: center;
  }

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */ 
   .App{
     padding-left: 20px !important;
   }
   input{
       width:90% !important;
   }
   button{
      width: 43% !important;
      margin-left: 0px;
      border-radius: 40px;
   }
  
  button:hover {
    opacity:1 !important;
    background-color: #DC3545 !important;
    color: #fff !important;
   
  }
  
  }