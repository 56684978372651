#followup{
    float: right;
    width: 40%;
    
}

.activity-header{
    display: flex !important;
    justify-content:space-evenly !important; 
    width: 100% !important;
}
.activity-body{
    
    display: none;
}

.searchbtn{
    width: 220px !important;
    
}