.space{
    color: white !important;
    
}

.taskbar {
    display: flex !important;
    justify-content:space-evenly !important; 
    width: 100% !important;
    background-color:#DC3545;
   
}

