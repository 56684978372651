.User{
    border-color: #474747;
    border: 1px solid;
    width: 30%;
    margin-top: 20px;
    margin-left: 20px;
    float: left;
    padding: 10px;
    text-align: center;
}

.cardimg{
    
    display: block;
    width: 100%;
    height: auto;
}
